require('./bootstrap');
import * as FilePond from 'filepond';
// Import the plugin code
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

FilePond.registerPlugin(FilePondPluginImagePreview);

const fileInputs = []

window.addEventListener("load", () => {
    document.querySelectorAll("input[type='file']").forEach((input) => {
        let filePondInput = FilePond.create(input, {
            storeAsFile: true,
            allowImagePreview: input.hasAttribute("imagePreview"),
            allowReorder: true,
        });
        fileInputs.push({
            inputName: input.getAttribute('name'),
            inputId: input.getAttribute('id'),
            filePond: filePondInput
        })
    })
    document.querySelectorAll("[name='iban']").forEach((input) => {
        input.addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        })
    })
})

window.fillForm = (selector, data) => {
    data = JSON.parse(data)
    for (let key in data) {
        let input = document.querySelector(`${selector} [name='${key}']`) ??
            (document.querySelector(`${selector} [type='file']`).hasAttribute("multiple") ?
                (document.querySelector(`${selector} [name='${key}[]']`)) :
                null)

        if (input) {
            if (typeof data[key] != 'object') {
                input.value = data[key]
            } else if (typeof data[key] === 'object') {
                if (key === "categories") {
                    window.Livewire.emit('toggle-edit-modal-category-clean')
                    data[key].forEach((category) => {
                        window.Livewire.emit('toggle-edit-modal-category-select', category.id)
                    })
                } else {
                    if (key === "image") {
                        let input = fileInputs.filter(input => {
                            return input.inputId === "image-edit"
                        })[0]
                        input.filePond.addFiles(data[key].path)
                    } else if (key === "file") {
                        let input = fileInputs.filter(input => {
                            return input.inputId === "file-edit"
                        })[0]
                        input.filePond.addFiles(data[key].path)
                    } else if (key === "slides") {
                        let input = fileInputs.filter(input => {
                            return input.inputId === "slides-edit"
                        })[0]
                        data[key].forEach((slide) => {
                            input.filePond.addFiles(slide.image.path)
                        })
                    }
                }
            }
        }
    }
}


