window._ = require('lodash');

const bootstrap = require("bootstrap")

import Toastify from 'toastify-js'

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.response.use((response) => {
        window.Livewire.emit("refresh")
        return response
    }
)

window.addEventListener("load", () => {

    document.querySelectorAll(".nav-item").forEach((menuItem) => {
        if (menuItem.querySelector(".dropdown-toggle")) {
            menuItem.querySelector(".dropdown-toggle").addEventListener("click", (element) => {
                menuItem.classList.toggle("open")
            })
        }
    })

    if (document.querySelector("#loader")) {
        setTimeout(() => {
            document.querySelector("#loader").classList.add("fade")

            setTimeout(() => {
                document.querySelector("#loader").remove()
            }, 500)

        }, 500)

    }

    document.querySelectorAll("button,i").forEach((button) => {
        if (button.hasAttribute("modal")) {
            let modalSelector = button.getAttribute("modal")
            if (modalSelector && modalSelector !== "") {
                button.addEventListener("click", () => {
                    let modal = new bootstrap.Modal(document.querySelector(modalSelector))
                    modal.show()
                })
            }
        }
    })

    document.querySelectorAll("form").forEach((form) => {

        if (form.hasAttribute("action") && form.hasAttribute("method") && form.hasAttribute("autosubmit")) {

            form.addEventListener("submit", async (event) => {
                event.preventDefault()

                let formData = new FormData(form)
                let url = form.getAttribute("action")
                let method = form.getAttribute("method")

                if (url.includes(":id")) {
                    url = url.replace(":id", formData.get("id"))
                }

                if (form.hasAttribute("hasbuttons")) {
                    let containerElement = form.querySelector(".button-container")
                    let key = containerElement.getAttribute("name")

                    containerElement.querySelectorAll(".btn").forEach((element, index) => {
                        if (element.getAttribute("data-id") && element.hasAttribute("selected")) {
                            formData.append(key + `[${index}]`, element.getAttribute("data-id"))
                        }
                    })
                }

                let request = {
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'X-BEARER-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
                    },
                    url: url,
                    method: method,
                    data: formData
                }


                let submitButton = form.querySelector("button[type='submit']")
                submitButton.innerHTML += '<div class="spinner-border" style="width: 1rem; height: 1rem;" role="status"><span class="visually-hidden"></span></div>'
                submitButton.style.display = "flex";
                submitButton.style.justifyContent = "center";
                submitButton.style.alignItems = "center";
                submitButton.style.gap = "11px";
                submitButton.disabled = true;

                axios(request).then((response) => {
                    submitButton.querySelector(".spinner-border").remove()
                    submitButton.disabled = false;
                    if (response.data.status === "success") {
                        Toastify({
                            text: response.data.message,
                            duration: 3000,
                            close: true,
                            gravity: "top",
                            position: "right",
                            stopOnFocus: true,
                            style: {
                                background: "linear-gradient(to right, #00b09b, #96c93d)",
                            },
                        }).showToast();

                        if (form.querySelector("[data-bs-dismiss='modal']")) {
                            form.querySelector("[data-bs-dismiss='modal']").click()
                        }
                        window.Livewire.emit("refresh")
                        form.reset()
                    }

                }).catch((errors) => {
                    submitButton.querySelector(".spinner-border").remove()
                    submitButton.disabled = false;

                    errors = errors.response.data.errors
                    for (const error in errors) {
                        form.querySelector(`[name='${error}']`).classList.add("is-invalid")
                        form.querySelector(`[name='${error}']`).addEventListener("focus", (event) => {
                            event.target.classList.remove("is-invalid")
                        })
                        Toastify({
                            text: errors[error],
                            duration: 3000,
                            close: true,
                            gravity: "top",
                            position: "right",
                            stopOnFocus: true,
                            style: {
                                background: "linear-gradient(to right, rgb(176 0 0), rgb(225 81 108))",
                            },
                        }).showToast();
                    }
                })
            })
        }
    })
})

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
